import React from "react";
import donatuzLogo from "../assets/donatuz.logo.svg";
import { ThirdwebProvider, ConnectButton, darkTheme } from "thirdweb/react";
import { createWallet, walletConnect, inAppWallet } from "thirdweb/wallets";
import { createThirdwebClient } from "thirdweb";

// Create the client
const client = createThirdwebClient({
  clientId: "b492a3e50399e9278ec5617b45d81d38",
});

// Define the wallets
const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  walletConnect(),
  inAppWallet({
    auth: {
      options: ["email", "google", "phone"],
    },
  }),
];

function Header() {
  return (
    <ThirdwebProvider>
      <header className="bg-transparent py-4">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center">
            <img
              src={donatuzLogo}
              alt="Donatuz Logo"
              className="h-8 w-auto mr-2"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = "none";
              }}
            />
          </div>
          <ConnectButton
            client={client}
            wallets={wallets}
            theme={darkTheme({
              colors: {
                primaryText: "#f8f8f1",
                primaryButtonBg: "#ddad27",
              },
            })}
            connectModal={{
              size: "wide",
              titleIcon: "",
              showThirdwebBranding: false,
            }}
          />
        </div>
      </header>
    </ThirdwebProvider>
  );
}

export default Header;
