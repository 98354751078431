import React from "react";
import Header from "./components/Header";
import Bridge from "./components/Bridge";
import { ThirdwebProvider } from "thirdweb/react";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <ThirdwebProvider>
      <div className="min-h-screen bg-[#080a1a] relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-[#1c1e3d] via-transparent to-[#1c1e3d] opacity-70"></div>

        <div className="absolute -top-1/4 -left-1/4 w-3/4 h-3/4 rounded-full bg-[#3b3f8c] blur-[100px] opacity-20"></div>

        <div className="absolute -bottom-1/4 -right-1/4 w-3/4 h-3/4 rounded-full bg-[#3b3f8c] blur-[100px] opacity-20"></div>

        <div className="relative z-10">
          <Header />
          <main className="container mx-auto px-4 py-8">
            <ErrorBoundary>
              <Bridge />
            </ErrorBoundary>
          </main>
        </div>
      </div>
    </ThirdwebProvider>
  );
}

export default App;
