import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, Settings, ArrowUpDown } from "lucide-react";
import SuccessPopup from "./SuccessPopup";
import FailurePopup from "./FailurePopup";
import { ethers } from "ethers";
import {
  useActiveAccount,
  useActiveWallet,
  useWalletBalance,
  useActiveWalletChain,
  useEstimateGas,
} from "thirdweb/react";
import {
  createThirdwebClient,
  getContract,
  prepareContractCall,
  toWei,
  readContract,
  sendTransaction,
  estimateGas,
  waitForReceipt,
} from "thirdweb";
import { baseSepolia, sepolia, ethereum, base } from "thirdweb/chains";
import backgroundSvg from "../assets/background.svg";
import { networkSymbols } from "../constants/networkSymbols";

const client = createThirdwebClient({
  clientId: "b492a3e50399e9278ec5617b45d81d38",
});

const bridgeContracts = {
  ethereum: "0x3154Cf16ccdb4C6d922629664174b904d80F2C35",
  base: "0xb765Df9d2925a82678F4B7bc60eF5294c1604514",
  sepolia: "0x49048044D57e1C92A77f79988d21Fa8fAF74E97e",
  "base sepolia": "0x4200000000000000000000000000000000000010",
};

const l1ToBridgeABI = [
  {
    inputs: [
      { internalType: "uint32", name: "_minGasLimit", type: "uint32" },
      { internalType: "bytes", name: "_extraData", type: "bytes" },
    ],
    name: "depositETH",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

const l2ToBridgeABI = [
  {
    inputs: [
      { internalType: "address", name: "_to", type: "address" },
      { internalType: "uint256", name: "_value", type: "uint256" },
      { internalType: "uint64", name: "_gasLimit", type: "uint64" },
      { internalType: "bool", name: "_isCreation", type: "bool" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "depositTransaction",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

function CustomDropdown({ value, onChange, options, className = "" }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full px-2 py-1 text-white text-sm font-semibold focus:outline-none"
      >
        <div className="flex items-center">
          {networkSymbols && networkSymbols[value] && (
            <img
              src={networkSymbols[value]}
              alt={value}
              className="w-5 h-5 mr-2"
            />
          )}
          <span className="truncate">{value}</span>
        </div>
        <ChevronDown
          className={`text-white ml-2 transition-transform duration-200 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          size={16}
        />
      </button>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white/5 backdrop-blur-md rounded shadow-lg">
          {options.map((option) => (
            <button
              key={option}
              onClick={() => {
                onChange(option);
                setIsOpen(false);
              }}
              className="flex items-center w-full text-left px-2 py-1 text-white text-sm hover:bg-white/30 transition-colors duration-150"
            >
              {networkSymbols && networkSymbols[option] && (
                <img
                  src={networkSymbols[option]}
                  alt={option}
                  className="w-5 h-5 mr-2"
                />
              )}
              <span>{option}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

const CustomSwitchIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.6654 5V8.33333H13.332V5H26.6654ZM26.6654 11.6667V15H13.332V11.6667H26.6654ZM26.6654 18.3333V21.6667H13.332V18.3333H26.6654ZM8.33203 25H31.6654L19.9987 36.6667L8.33203 25Z"
      fill="white"
    />
  </svg>
);

function Bridge() {
  const [fromChain, setFromChain] = useState("Ethereum");
  const [toChain, setToChain] = useState("Base");
  const [fromAmount, setFromAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [fromToken, setFromToken] = useState("ETH");
  const [toToken, setToToken] = useState("ETH");
  const [sameNetworkError, setSameNetworkError] = useState(false);
  const [chainMismatch, setChainMismatch] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [transactionHash, setTransactionHash] = useState("");
  const [isL1ToL3Transfer, setIsL1ToL3Transfer] = useState(false);
  const [bal, setBal] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [showFailurePopup, setShowFailurePopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const activeAccount = useActiveAccount();
  const activeWalletChain = useActiveWalletChain();

  const ethereumProvider = new ethers.JsonRpcProvider(
    "https://eth-mainnet.g.alchemy.com/v2/JwkRF_vQJWBYFj5wXkkhUnYi1A5TwFhs"
  );
  const baseProvider = new ethers.JsonRpcProvider("https://mainnet.base.org");

  const { mutate: estimateGasCost, data: gasEstimate } = useEstimateGas();

  console.log("Active Account", activeAccount);
  console.log("active wallet chain", activeWalletChain?.name);
  console.log("active wallet", activeWalletChain);

  const getChainObject = (chainName) => {
    switch (chainName?.toLowerCase()) {
      case "ethereum":
        return ethereum;
      case "base sepolia":
        return baseSepolia;
      case "sepolia":
        return sepolia;
      case "base":
        return base;

      default:
        return ethereum;
    }
  };

  const {
    data: balance,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
  } = useWalletBalance({
    chain: getChainObject(activeWalletChain?.name || "Ethereum"),
    address: activeAccount?.address,
    client,
  });

  useEffect(() => {
    if (activeWalletChain && fromChain) {
      const normalizedActiveChain = normalizeChainName(activeWalletChain.name);
      const normalizedFromChain = normalizeChainName(fromChain);
      setChainMismatch(normalizedActiveChain !== normalizedFromChain);
    }
  }, [activeWalletChain, fromChain]);

  useEffect(() => {
    if (balance?.symbol) {
      setFromToken(balance.symbol);
    }
  }, [balance]);

  useEffect(() => {
    setSameNetworkError(
      normalizeChainName(fromChain) === normalizeChainName(toChain)
    );
  }, [fromChain, toChain]);

  useEffect(() => {
    const initProvider = async () => {
      if (window.ethereum) {
        const web3Provider = new ethers.BrowserProvider(window.ethereum);
        setProvider(web3Provider);
        try {
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const signer = await web3Provider.getSigner();
          setSigner(signer);
          const address = await signer.getAddress();
          const bal = await web3Provider.getBalance(address);
          setBal(ethers.formatEther(bal));
        } catch (error) {
          console.error("Failed to connect wallet:", error);
        }
      } else {
        console.log("Please install MetaMask!");
      }
    };

    initProvider();
  }, []);

  const normalizeChainName = (chainName) => {
    return chainName?.toLowerCase().replace(/\s+/g, "");
  };
  const handleFromChainChange = (chain) => {
    console.log("From chain changed to:", chain);
    setFromChain(chain);
    updateL1ToL3TransferState(chain, toChain);
  };

  const handleToChainChange = (chain) => {
    console.log("To chain changed to:", chain);
    setToChain(chain);
    updateL1ToL3TransferState(fromChain, chain);
  };

  const updateL1ToL3TransferState = (from, to) => {
    console.log("Updating L1ToL3 state:", { from, to });
    const isL1ToL3 =
      from.toLowerCase() === "ethereum" && to.toLowerCase() === "donatuz";
    console.log("Is L1 to L3 transfer:", isL1ToL3);
    setIsL1ToL3Transfer(isL1ToL3);
  };

  const handleFromAmountChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setFromAmount(value);
      setToAmount(value);
    }
  };

  const handleMaxClick = () => {
    if (balance?.displayValue) {
      setFromAmount(balance.displayValue);
      setToAmount(balance.displayValue);
    }
  };

  const handleSwitch = () => {
    setFromChain(toChain);
    setToChain(fromChain);
    setFromAmount("");
    setToAmount("");
    setFromToken(toToken);
    setToToken(fromToken);
  };

  const handleTransfer = async () => {
    if (isTransferring || chainMismatch || sameNetworkError || !fromAmount) {
      console.log("Cannot proceed with transfer.");
      return;
    }
    setIsTransferring(true);
    try {
      if (isL1ToL3Transfer) {
        await executeL1ToL3Transfer();
      } else {
        await executeSingleTransfer();
      }
      setShowSuccessPopup(true);
    } catch (error) {
      console.error("Error during transfer:", error);
      setErrorMessage(error.message || "An unknown error occurred");
      setShowFailurePopup(true);
    } finally {
      setIsTransferring(false);
    }
  };

  useEffect(() => {
    const handleNetworkChange = (chainId) => {
      // This will update the chainMismatch state, which triggers the alert
      if (activeWalletChain) {
        const normalizedActiveChain = normalizeChainName(
          activeWalletChain.name
        );
        const normalizedFromChain = normalizeChainName(fromChain);
        setChainMismatch(normalizedActiveChain !== normalizedFromChain);
      }
    };

    window.ethereum?.on("chainChanged", handleNetworkChange);

    return () => {
      window.ethereum?.removeListener("chainChanged", handleNetworkChange);
    };
  }, [activeWalletChain, fromChain]);

  const executeSingleTransfer = async () => {
    const fromChainId = getChainId(fromChain);
    const bridgeContractAddress = bridgeContracts[fromChain.toLowerCase()];
    if (!bridgeContractAddress) {
      throw new Error(`Bridge contract not found for ${fromChain}`);
    }
    const amountWei = toWei(fromAmount);
    let tx;

    if (
      fromChain.toLowerCase() === "ethereum" ||
      fromChain.toLowerCase() === "sepolia"
    ) {
      const contract = getContract({
        client,
        chain: fromChainId,
        address: bridgeContractAddress,
        abi: l1ToBridgeABI,
      });

      tx = prepareContractCall({
        contract,
        method:
          "function depositETH(uint32 _minGasLimit, bytes _extraData) payable",
        params: [200000, "0x"],
        value: amountWei,
      });

      console.log("Transaction sent. Hash:", tx.transactionHash);
      setTransactionHash(tx.transactionHash);

      const receipt = await waitForReceipt(tx);
      console.log("Transaction confirmed in block:", receipt.blockNumber);
      console.log("Gas used:", receipt.gasUsed.toString());
    } else if (fromChain.toLowerCase() === "base") {
      const contract = new ethers.Contract(
        bridgeContractAddress,
        l2ToBridgeABI,
        signer
      );
      const gasEstimate = await contract.depositTransaction.estimateGas(
        await signer.getAddress(),
        amountWei,
        100000n,
        false,
        "0x",
        { value: amountWei }
      );
      const feeData = await provider.getFeeData();

      tx = await contract.depositTransaction(
        await signer.getAddress(),
        amountWei,
        100000n,
        false,
        "0x",
        {
          value: amountWei,
          gasLimit: (gasEstimate * 150n) / 100n,
          maxFeePerGas: feeData.maxFeePerGas,
          maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
        }
      );
    } else {
      throw new Error(`Unsupported chain: ${fromChain}`);
    }

    console.log("Transaction sent. Hash:", tx.hash);
    setTransactionHash(tx.hash);

    const receipt = await tx.wait();
    console.log("Transaction confirmed in block:", receipt.blockNumber);
    console.log("Gas used:", receipt.gasUsed.toString());
  };

  const executeL1ToL3Transfer = async () => {
    try {
      // Check if the wallet is connected
      if (!window.ethereum) {
        throw new Error("Please install MetaMask or another web3 wallet");
      }

      // Request account access
      await window.ethereum.request({ method: "eth_requestAccounts" });

      // Step 1: Transfer from Ethereum (L1) to Base (L2)
      const l1BridgeContractAddress = bridgeContracts["ethereum"];
      if (!l1BridgeContractAddress) {
        throw new Error("Bridge contract not found for Ethereum");
      }
      const amountWei = ethers.parseEther(fromAmount);

      // Create providers for Ethereum and Base
      const ethereumProvider = new ethers.BrowserProvider(window.ethereum);
      const ethereumSigner = await ethereumProvider.getSigner();

      // Switch to Ethereum network
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }], // Ethereum Mainnet
      });

      const l1Contract = new ethers.Contract(
        l1BridgeContractAddress,
        l1ToBridgeABI,
        ethereumSigner
      );

      console.log("Initiating L1 to L2 transfer...");
      const l1Tx = await l1Contract.depositETH(200000, "0x", {
        value: amountWei,
        gasLimit: 300000, // You might want to estimate this
      });

      console.log("L1 to L2 Transaction sent. Hash:", l1Tx.hash);
      setTransactionHash(l1Tx.hash);

      const l1Receipt = await l1Tx.wait();
      console.log(
        "L1 to L2 Transaction confirmed in block:",
        l1Receipt.blockNumber
      );
      console.log("L1 to L2 Gas used:", l1Receipt.gasUsed.toString());

      // Wait for the L1 to L2 transaction to be fully processed
      // You might need to implement a waiting mechanism or use an API to check when funds are available on Base

      // Step 2: Transfer from Base (L2) to Donatuz (L3)
      const l2BridgeContractAddress = bridgeContracts["base"];
      if (!l2BridgeContractAddress) {
        throw new Error("Bridge contract not found for Base");
      }

      // Switch to Base network
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x2105" }], // Base Mainnet
      });

      // Create a new provider for Base
      const baseProvider = new ethers.BrowserProvider(window.ethereum);
      const baseSigner = await baseProvider.getSigner();

      const l2Contract = new ethers.Contract(
        l2BridgeContractAddress,
        l2ToBridgeABI,
        baseSigner
      );

      // Check balance on Base before proceeding
      const baseBalance = await baseProvider.getBalance(
        await baseSigner.getAddress()
      );
      if (baseBalance < amountWei) {
        throw new Error("Insufficient balance on Base for L2 to L3 transfer");
      }

      const gasEstimate = await l2Contract.depositTransaction.estimateGas(
        await baseSigner.getAddress(),
        amountWei,
        100000,
        false,
        "0x",
        { value: amountWei }
      );

      const l2Tx = await l2Contract.depositTransaction(
        await baseSigner.getAddress(),
        amountWei,
        100000,
        false,
        "0x",
        {
          value: amountWei,
          gasLimit: (gasEstimate * 15n) / 10n, // Add 50% buffer
        }
      );

      console.log("L2 to L3 Transaction sent. Hash:", l2Tx.hash);
      setTransactionHash(l2Tx.hash);

      const l2Receipt = await l2Tx.wait();
      console.log(
        "L2 to L3 Transaction confirmed in block:",
        l2Receipt.blockNumber
      );
      console.log("L2 to L3 Gas used:", l2Receipt.gasUsed.toString());

      console.log(
        "L1 to L3 transfer completed. It may take some time for the funds to appear on Donatuz L3."
      );
    } catch (error) {
      console.error("Error in executeL1ToL3Transfer:", error);
      throw error;
    }
  };
  const getChainId = (chainName) => {
    const chainMap = {
      Ethereum: 1,
      BaseSepolia: 84532,
      Sepolia: 11155111,
      Polygon: 137,
      base: 8453,
    };
    return chainMap[chainName] || 1; // Default to Ethereum mainnet if not found
  };

  const isTransferDisabled =
    !signer ||
    chainMismatch ||
    sameNetworkError ||
    !fromAmount ||
    isTransferring;

  return (
    <div className="min-h-screen flex flex-col justify-start items-center p-4 sm:p-6 md:p-8 pt-6 sm:pt-2">
      <div className="mt-0 sm:mt-5 md:mt-10 backdrop-blur-xl bg-white/10 shadow-lg p-4 sm:p-6 md:p-7 w-full max-w-[95%] sm:max-w-[540px] md:max-w-[600px] rounded-2xl max-h-[90vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
        {chainMismatch && (
          <div
            className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 sm:p-3 mb-3 sm:mb-4 text-xs sm:text-sm rounded-xl"
            role="alert"
          >
            <p>
              Warning: Selected chain ({fromChain}) doesn't match your wallet's
              current network ({activeWalletChain?.name}). Please switch
              networks in your wallet or select the correct chain.
            </p>
          </div>
        )}
        {sameNetworkError && (
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-2 sm:p-3 mb-3 sm:mb-4 text-xs sm:text-sm rounded-xl"
            role="alert"
          >
            <p>
              Error: From and To networks cannot be the same. Please select
              different networks for bridging.
            </p>
          </div>
        )}
        <div className="flex justify-center items-center mb-4 sm:mb-5">
          <h2 className="text-white text-xl sm:text-2xl font-bold">Transfer</h2>
        </div>
        <div className="mb-4 sm:mb-6 backdrop-blur-md bg-white/[0.03] p-3 sm:p-4 rounded-xl">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center -space-x-1 flex-grow">
              <span className="text-gray-300 text-sm sm:text-base font-semibold w-10 sm:w-12">
                From
              </span>
              <CustomDropdown
                value={fromChain}
                onChange={handleFromChainChange}
                options={["Ethereum", "Base"]}
                className="flex-grow max-w-[120px] sm:max-w-[140px]"
              />
            </div>
            <div className="text-right">
              <span className="text-gray-300 text-xs sm:text-sm">
                Bal:{" "}
                {isBalanceLoading
                  ? "Loading..."
                  : isBalanceError
                  ? "Error"
                  : balance?.displayValue || "0"}{" "}
                {fromToken}
              </span>
              <button
                className="text-purple-400 text-xs sm:text-sm ml-1"
                onClick={handleMaxClick}
              >
                MAX
              </button>
            </div>
          </div>
          <div className="border-t border-white/10 my-2"></div>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              inputMode="decimal"
              value={fromAmount}
              onChange={handleFromAmountChange}
              className="bg-transparent text-white text-base sm:text-lg w-full focus:outline-none placeholder-white/50"
              placeholder="0.0"
            />
            <div className="bg-white/[0.08] rounded-lg p-1">
              <CustomDropdown
                value={fromToken}
                onChange={setFromToken}
                options={["ETH"]}
                className="w-16 sm:w-20"
              />
            </div>
          </div>
        </div>
        <div className="relative flex items-center justify-center my-2 sm:my-3">
          <div className="border-t border-white/30 flex-grow mr-2"></div>
          <button
            onClick={handleSwitch}
            className="bg-white/10 p-1 sm:p-2 hover:bg-white/30 transition-colors duration-200 rounded-full"
          >
            <CustomSwitchIcon className="w-4 h-4 sm:w-5 sm:h-5" />
          </button>
          <div className="border-t border-white/30 flex-grow ml-2"></div>
        </div>
        <div className="mt-4 sm:mt-6 mb-4 sm:mb-5 backdrop-blur-md bg-white/[0.03] p-3 sm:p-4 rounded-xl">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center -space-x-1 flex-grow">
              <span className="text-gray-300 text-sm sm:text-base font-semibold w-10 sm:w-12">
                To
              </span>
              <CustomDropdown
                value={toChain}
                onChange={handleToChainChange}
                options={["Base", "Donatuz"]}
                className="flex-grow max-w-[120px] sm:max-w-[140px]"
              />
            </div>
          </div>
          <div className="border-t border-white/30 my-2"></div>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={toAmount}
              className="bg-transparent text-white text-base sm:text-lg w-full focus:outline-none cursor-not-allowed placeholder-white/50"
              placeholder="0.0"
              readOnly
            />
            <div className="bg-white/[0.08] rounded-lg p-1">
              <CustomDropdown
                value={toToken}
                onChange={setToToken}
                options={["ETH"]}
                className="w-16 sm:w-20"
              />
            </div>
          </div>
        </div>
        {isL1ToL3Transfer && (
          <div className="mb-4 sm:mb-5 p-3 sm:p-4 bg-white/[0.03] shadow-md text-xs sm:text-sm rounded-xl">
            <div className="flex items-start">
              <svg
                className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400 mr-2 mt-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div>
                <h4 className="text-sm sm:text-base font-semibold text-blue-400 mb-1">
                  Two-Step Transfer
                </h4>
                <p className="text-gray-300 text-xs sm:text-sm">
                  This transfer will be executed in two steps:
                </p>
                <ol className="list-decimal list-inside mt-1 text-gray-300 text-xs sm:text-sm">
                  <li>Ethereum to Base</li>
                  <li>Base to Donatuz</li>
                </ol>
              </div>
            </div>
          </div>
        )}

        {/* Transfer button */}
        <button
          onClick={handleTransfer}
          disabled={isTransferDisabled}
          className={`
          bg-purple-600 text-white font-bold py-2 sm:py-3 px-4 w-full 
          transition duration-300 text-sm sm:text-base rounded-xl
          ${
            isTransferDisabled
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-purple-700"
          }
        `}
        >
          {!activeAccount
            ? "Connect Wallet"
            : chainMismatch
            ? "Network Mismatch"
            : sameNetworkError
            ? "Same Network Error"
            : isTransferring
            ? "Transferring..."
            : isL1ToL3Transfer
            ? "Transfer (2 steps)"
            : "Transfer"}
        </button>

        <SuccessPopup
          message="The Funds have been Successfully Transferred"
          fromChain={fromChain}
          toChain={toChain}
          isVisible={showSuccessPopup}
          onClose={() => setShowSuccessPopup(false)}
        />
        <FailurePopup
          error={errorMessage}
          fromChain={fromChain}
          toChain={toChain}
          isVisible={showFailurePopup}
          onClose={() => setShowFailurePopup(false)}
        />
      </div>
    </div>
  );
}

export default Bridge;
