import React, { useState, useEffect } from "react";
import { X } from "lucide-react";

const getErrorMessage = (error) => {
  if (typeof error === "string") {
    if (error.includes("network changed")) {
      const match = error.match(/network changed: (\d+) => (\d+)/);
      if (match) {
        const [, oldNetwork, newNetwork] = match;
        return `Network changed unexpectedly from ${oldNetwork} to ${newNetwork}. Please switch back to the correct network and try again.`;
      }
    }
    return error; // Return the error string if it's not a specific case
  }
  if (error instanceof Error) {
    return error.message; // Return the error message for Error objects
  }
  return "An unexpected error occurred. Please try again.";
};

const FailurePopup = ({ error, fromChain, toChain, isVisible, onClose }) => {
  const [isActive, setIsActive] = useState(false);
  const errorMessage = getErrorMessage(error);

  useEffect(() => {
    if (isVisible) {
      setIsActive(true);
      const timer = setTimeout(() => {
        setIsActive(false);
        setTimeout(onClose, 500);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isVisible, onClose]);

  if (!isVisible && !isActive) return null;

  return (
    <div
      className={`fixed bottom-0 left-0 right-0 flex justify-center items-center p-4 transition-transform duration-300 ease-in-out ${
        isActive ? "translate-y-0" : "translate-y-full"
      }`}
    >
      <div className="bg-red-800 text-white rounded-lg shadow-lg p-4 flex items-center space-x-4 max-w-md w-full">
        <div className="bg-red-500 rounded-full p-2">
          <X size={24} />
        </div>
        <div className="flex-1">
          <p className="font-bold text-lg">Transaction Failed</p>
          <p className="text-sm mb-2">{errorMessage}</p>
          {fromChain && toChain && (
            <p className="text-xs">
              Failed to transfer from{" "}
              <span className="font-semibold">{fromChain}</span> to{" "}
              <span className="font-semibold">{toChain}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FailurePopup;
